@font-face {
  font-family: "Cabinet Grotesk";
  src: local("Cabinet Grotesk"),
    url(./fonts/CabinetGrotesk-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Cabinet GroteskBold";
  src: local("Cabinet GroteskBold"),
    url(./fonts/CabinetGrotesk-Extrabold.ttf) format("truetype");
}

@font-face {
  font-family: "Cabinet Grotesk Variable";
  src: local("Cabinet Grotesk Variable"),
    url(./fonts/CabinetGrotesk-Variable.ttf) format("truetype");
}

.header {
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
}

.none1 {
  display: none;
}

.nav {
  padding-top: 40px;
}

.ham {
  display: none;
}

.logo {
  margin-right: 90px;
}

a {
  text-decoration: none;
}

.header-inner {
  display: flex;
  margin-left: 134px;
}

.header-contact {
  margin-right: 57px;
}

.header-text {
  padding-top: 40px;
  padding-right: 65px;
  color: #003e51;
  font-family: "Cabinet Grotesk";
}

.activeStyle {
  color: #0eca0a;
  padding-top: 40px;
  padding-right: 65px;
  font-family: "Cabinet Grotesk";
}

.home-section1 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.home-section1-text1 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  color: #0eca0a;
}

.home-section1-text2 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  color: #003e51;
}

.home-section1-text3 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #003e51;
  width: 75%;
}

.home-section1-inner {
  margin-top: 200px;
}

.img1 {
  margin-top: 64px;
}

.bg1 {
  width: 100%;
  margin-top: 100px;
}

.home-section2 {
  background: #003e51;
  display: flex;
  justify-content: space-between;
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  top: -200px;
}

.home-section1-text4 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 24px;
}

.home-section1-text5 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.home-section3 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -80px;
}

.home-section1-text6 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #003e51;
  width: 90%;
}

.home-section1-text7 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  color: #003e51;
}

.home-section3-inner {
  width: 55%;
  margin-top: 180px;
}

.home-section1-text8 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  text-align: center;
  color: #003e51;
  width: 48%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}

.home-card {
  width: 549px;
  background: #ffffff;
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.08);
  padding: 32px;
  box-sizing: border-box;
}

.home-section4-list {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.home-section4 {
  margin-top: 50px;
  padding-bottom: 150px;
}

.home-section1-text9 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #003e51;
  margin-bottom: 24px;
}

.home-section1-text10 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #003e51;
}

.home-section5 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.home-section1-text11 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  color: #003e51;
}

.home-section1-text12 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #003e51;
  width: 80%;
  margin-top: 24px;
  margin-bottom: 50px;
}

.home-section5-inner {
  width: 50%;
}

.home-button {
  width: 283px;
  height: 64px;
  border: none;
  background: #0eca0a;
  border-radius: 24px;
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #003e51;
  display: flex;
  padding-left: 40px;
  padding-top: 10px;
}

.order {
  padding-top: 15px;
  padding-left: 10px;
}

.footer-inner {
  background: #003e51;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
}

.footer-inner1 {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

.footer-inner2 {
  display: flex;
}

.footer-text {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  margin-right: 24px;
}

.footer-text-list {
  margin-top: 30px;
  margin-left: 50px;
}

.hr {
  opacity: 0.32;
  border: 1px solid #ffffff;
}

.footer-text1 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 28px;
}

.footer-social {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.footer-text2 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  padding-top: 24px;
}

.footer_bg2 {
  width: 100%;
  position: relative;
  bottom: -200px;
}

.notfound {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.notfound-text1 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  color: #003e51;
  margin-bottom: 24px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.notfound-text2 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 170%;
  text-align: center;
  color: #003e51;
  margin-bottom: 41px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.notfound-text3 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #0eca0a;
  text-align: center;
}

.char {
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  display: block;
  margin-bottom: 50px;
}

.logo2 {
  display: block;
}

.social {
  display: block;
}

@media (max-width: 500px) {
  .home-section1 {
    width: 100%;
    flex-direction: column;
  }

  .home-section3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -80px;

    flex-direction: column;
  }

  .home-section5 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;

    flex-direction: column;
  }

  .footer-inner1 {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;

    flex-direction: column;
  }

  .footer-inner2 {
    display: flex;

    flex-direction: column;
  }

  .header-inner {
    display: flex;
    margin-left: 134px;
  }

  .nav {
    display: block;
    padding-left: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 90%;
    height: 250px;
    position: absolute;
    top: 60px;
    left: 0px;
    background-color: #ffffff;
    z-index: 1;
  }

  .header-text {
    padding-right: 0px;
    color: #003e51;
    display: block;
  }

  .none {
    display: none;
  }

  .logo {
    margin-right: 0px;
  }

  .logo2 {
    display: block;
  }

  .img1 {
    margin-top: 64px;
    width: 100%;
  }

  .img2 {
    width: 100%;
  }
  .img3 {
    width: 100%;
  }

  .home-section4-list {
    flex-direction: column;
  }

  .home-card {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding: 16px;
  }

  .activeStyle {
    padding-right: 0px;
    display: block;
  }

  .header-inner {
    display: flex;
    margin-left: 0px;
  }

  .ham {
    display: block;
    padding-right: 30px;
  }

  .animation1 {
    transform: translateX(0);
    transition: transform 1.5s;
  }

  .animation2 {
    transform: translateX(-1000px);
    transition: transform 1.5s;
  }

  .home-section1-text1 {
    font-weight: 700;
    font-size: 32px;
  }

  .home-section1-text2 {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 16px;
  }

  .home-section1-text3 {
    font-size: 16px;
    color: #003e51;
    width: 90%;
  }

  .home-section1-inner {
    margin-top: 30px;
    padding-left: 24px;
  }

  .img1,
  .img2,
  .img3 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .home-section2 {
    background: #003e51;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    top: -200px;
  }

  .bg1 {
    width: 100%;
    margin-top: 50px;
    height: 300px;
  }

  .home-section1-text4 {
    font-size: 32px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 24px;
  }

  .home-section1-text5 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    line-height: 2rem;
  }

  .home-section3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: relative;
    top: -250px;
  }

  .home-section1-text7 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    color: #003e51;
    margin-top: 50px;
  }

  .home-section3-inner {
    width: 100%;
    margin-top: 80px;
  }

  .home-section1-text6 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003e51;
    width: 90%;
    line-height: 2rem;
    margin-bottom: 50px;
    margin-top: 24px;
  }

  .home-section1-text8 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    text-align: center;
    color: #003e51;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }

  .home-section4 {
    margin-top: 0px;
    padding-bottom: 0px;
    position: relative;
    top: -100px;
  }

  .home-section1-text9 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #003e51;
    margin-bottom: 24px;
  }

  .home-section1-text10 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003e51;
    line-height: 2rem;
  }

  .home-section1-text11 {
    font-size: 32px;
  }

  .home-section1-text12 {
    font-size: 32px;
  }

  .home-section5-inner {
    width: 100%;
  }

  .home-section1-text12 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003e51;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 50px;
  }

  .home-button {
    width: 100%;
    height: 56px;
    border: none;
    background: #0eca0a;
    border-radius: 24px;
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #003e51;
    display: flex;
    padding-left: 25%;
    padding-top: 5px;
    margin-bottom: 30px;
  }

  .footer-text {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    margin-right: 0px;
    display: block;
    margin-bottom: 24px;
  }

  .footer-text-list {
    margin-top: 30px;
    margin-left: 10px;
  }

  .footer-text1 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 28px;
    margin-bottom: 24px;
    margin-left: 10px;
  }
}
