.team-text1 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  text-align: center;
  color: #003e51;
  margin-top: 64px;
}

.team-text2 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #003e51;
  width: 38%;
  margin-left: auto;
  margin-right: auto;
}

.img4 {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}

.team-text3 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding-top: 23px;
  text-align: center;

  color: #ffffff;
}

.team-text4 {
  font-family: "Cabinet Grotesk Variable";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #ffffff;
}

.team-section2 {
  display: flex;
  justify-content: space-between;
  background: #003e51;
  padding: 80px;
  margin-top: 64px;
}

@media (max-width: 500px) {
  .team-section2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #003e51;
    padding: 80px;
    margin-top: 64px;
  }

  .img5 {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .team-text1 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    text-align: center;
    color: #003e51;
    margin-top: 64px;
    margin-bottom: 16px;
  }

  .team-text2 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #003e51;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .img4 {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
  }

  .team-section2 {
    display: flex;
    justify-content: space-between;
    background: none;
    padding: 10px;
    margin-top: 64px;
  }

  .team-text3 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding-top: 23px;
    text-align: center;
    color: #003e51;
  }

  .team-text4 {
    font-family: "Cabinet Grotesk Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #003e51;
    margin-bottom: 30px;
  }
}
