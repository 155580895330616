.form-text1 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  color: #003e51;
  margin-bottom: 16px;
}

.form-text2 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #003e51;
  margin-bottom: 48px;
}

.form-section {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.label {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #003e51;
  padding-bottom: 30px;
  display: block;
}

.input {
  width: calc(100% - 15px);
  height: 64px;
  border: 1px solid #767575;
  border-radius: 221px;
  padding-left: 15px;
}

.form-box {
  margin-bottom: 30px;
}

.tarea {
  width: 100%;
  height: 100px;
  border-radius: 32px;
  padding-left: 10px;
  padding-top: 10px;
}

.form-button {
  width: 100%;
  height: 64px;
  background: #0eca0a;
  border-radius: 24px;
  border: none;
}

@media (max-width: 768px) {
  .form-section {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
}
