.farm-section1 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
}

.farm-text1 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  color: #003e51;
}

.farm-text2 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #003e51;
  width: 45%;
  margin-top: 110px;
}

.bg2 {
  width: 90%;
  height: 45vh;
  margin-left: auto;
  margin-right: auto;
  top: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background: url("../../public/images/bg2.png");
}

.farm-section2 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background: url("../../public/images/tomatoes.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 70vh;
  margin-top: 112px;
}

.farm-text3 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  color: #ffffff;
  text-align: center;
}

.farm-button {
  width: 282px;
  height: 64px;
  background: #0eca0a;
  border-radius: 24px;
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #003e51;
  border: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.farm-section-inner {
  padding-top: 250px;
}

.bell {
  background: url("../../public/images/bell.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50vh;
  width: 48%;
}

.cucumber {
  background: url("../../public/images/cucumber.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50vh;
  width: 48%;
}

.farm-section3 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.farm-section3-inner {
  padding-top: 150px;
}

.farm-section4 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background: url("../../public/images/cowpea.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 70vh;
  margin-top: 24px;
}

@media (max-width: 500px) {
  .farm-section1 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 50px;
  }

  .farm-text1 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    color: #003e51;
  }

  .farm-text2 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003e51;
    width: 100%;
    margin-top: 20px;
  }

  .bg2 {
    width: 90%;
    height: 40vh;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
    top: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background: url("../../public/images/bg22.png");
  }

  .farm-section3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 24px;
  }

  .bell {
    background: url("../../public/images/bell.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: 100%;
    margin-bottom: 50px;
  }

  .cucumber {
    background: url("../../public/images/cucumber.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: 100%;
  }

  .farm-text3 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    color: #ffffff;
    text-align: center;
  }

  .farm-section2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: url("../../public/images/tomatoes.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    margin-top: 112px;
  }

  .farm-section-inner {
    padding-top: 150px;
  }

  .farm-section4 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: url("../../public/images/cowpea.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    margin-top: 24px;
  }
}
