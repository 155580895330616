.tomato-section1 {
  display: flex;
  justify-content: space-between;
}

.tomatoes-text1 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  color: #003e51;
}

.tomatoes-text2 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #003e51;
  width: 85%;
}

.tomato-section1-inner {
  margin-left: 90px;
  margin-top: 123px;
}

.tomatoe-section2 {
  display: flex;
  margin-top: 150px;
  width: 100%;
}

.fruits {
  width: 25%;
}

.tomatoe-section3 {
  background: #003e51;
  padding-top: 100px;
  padding-bottom: 100px;
}

.tomatoes-text3 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  text-align: center;
  color: #ffffff;
}

.tomatoes-text4 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}

.tomatoe-button {
  width: 283px;
  height: 64px;
  border: none;
  background: #0eca0a;
  border-radius: 24px;
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #003e51;
  display: flex;
  padding-left: 40px;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.tomatoe-section4 {
  padding-top: 50px;
}

.tomatoe-section4-inner {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.tomatoes-text5 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  color: #003e51;
  text-align: center;
  margin-bottom: 32px;
}

.bell-section1 {
  background: url("../../public/images/b1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
}

.cowpea-section1 {
  background: url("../../public/images/c1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
}

.cucumber-section1 {
  display: flex;
  justify-content: space-between;
  background: #003e51;
}

.cucumber-text1 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  color: white;
}

.cucumber-text2 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: white;
  width: 85%;
}

.cucumber-section1-inner {
  margin-left: 90px;
  margin-top: 200px;
}

@media (max-width: 500px) {
  .tomatoes-text1 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    color: #003e51;
  }

  .tomato-section1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .tomato-section1-inner {
    margin-left: 24px;
    margin-top: 50px;
  }

  .tomatoes-text2 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003e51;
    width: 90%;
    margin-top: 16px;
    line-height: 2rem;
    margin-bottom: 30px;
  }

  .t1 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .tomatoes-text3 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    text-align: center;
    color: #ffffff;
  }

  .tomatoe-section3 {
    background: #003e51;
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .tomatoes-text4 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    padding-top: 10px;
    line-height: 2rem;
  }

  .tomatoes-text5 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    color: #003e51;
    text-align: center;
    margin-bottom: 32px;
  }

  .tomatoe-section2 {
    display: flex;
    margin-top: 150px;
    width: 100%;
    margin-bottom: 100px;
  }

  .play2 {
    height: 500px;
  }

  .cucumber-section1 {
    display: flex;
    justify-content: space-between;
    background: #003e51;
    flex-direction: column;
  }

  .cucumber-text1 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    color: white;
  }

  .cucumber-section1-inner {
    margin-left: 20px;
    margin-top: 70px;
  }

  .cucumber-text2 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
    width: 95%;
    margin-bottom: 50px;
  }

  .cowpea-section1 {
    background: url("../../public/images/c1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 60vh;
  }
}
