.contact-section1 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 73px;
}

.contact-text1 {
  font-family: "Cabinet GroteskBold";
  font-style: normal;
  font-weight: 900;
  font-size: 96px;
  color: #003e51;
  margin-top: 200px;
}

.contact-text2 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #003e51;
  width: 70%;
}

.contact-section2 {
  background: #003e51;
  display: flex;
  padding: 90px;
  justify-content: space-between;
  margin-top: 100px;
}

.contact-text3 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  color: #ffffff;
  margin-top: 200px;
}

.contact-list {
  display: flex;
}

.contact-text4 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  padding-left: 10px;
  padding-top: 20px;
}

.contact-text5 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  padding-top: 10px;
  padding-left: 10px;
}

.contact-text6 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #0eca0a;
  padding-left: 10px;
  text-decoration: underline;
}

.contact-form {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  width: 40%;
  padding-bottom: 30px;
}

.contact-contact {
  width: 50%;
  border-right: 1px solid #ffffff;
}

.contact-text7 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  color: #003e51;
  margin-top: 96px;
  margin-bottom: 48px;
}

.contact-box {
  margin-bottom: 30px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.contact-text8 {
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #003e51;
  padding-bottom: 10px;
}

.contact-input {
  width: 419px;
  height: 64px;
  border: 1px solid #767575;
  border-radius: 24px;
  padding-left: 10px;
}

.contact-textarea {
  width: 419px;
  height: 200px;
  border: 1px solid #767575;
  border-radius: 24px;
  padding-left: 10px;
  padding-top: 10px;
}

.contact-button {
  width: 419px;
  height: 64px;
  background: #0eca0a;
  border-radius: 24px;
  border: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #003e51;
}

.or {
  position: relative;
  left: 58%;
  background: #003e51;
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #ffffff;
  padding-top: 10px;
}

@media (max-width: 500px) {
  .contact-section1 {
    width: 90%;
    flex-direction: column;
    margin-top: 73px;
  }

  .contact-section2 {
    background: #003e51;
    display: flex;
    flex-direction: column;
    padding: 90px;
    justify-content: space-between;
    margin-top: 100px;
  }

  .or {
    display: none;
  }

  .contact-form {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    width: 100%;
    padding-bottom: 30px;
    margin-top: 50px;
  }

  .contact-input {
    width: 100%;
    height: 56px;
    border: 1px solid #767575;
    border-radius: 24px;
    padding-left: 10px;
  }

  .contact-textarea {
    width: 100%;
    height: 200px;
    border: 1px solid #767575;
    border-radius: 24px;
    padding-left: 10px;
    padding-top: 10px;
  }

  .contact-button {
    width: 90%;
    height: 56px;
    background: #0eca0a;
    border-radius: 24px;
    border: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #003e51;
  }

  .contact-section2 {
    background: #003e51;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    margin-top: 50px;
  }

  .contact-contact {
    width: 100%;
    border-right: none;
  }

  .contact-text3 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    color: #ffffff;
    margin-top: 50px;
  }

  .contact-text4 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    padding-left: 10px;
    padding-top: 20px;
  }

  .contact-text1 {
    font-family: "Cabinet GroteskBold";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    color: #003e51;
    margin-top: 0px;
  }
  .contact-text2 {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #003e51;
    width: 90%;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
